<template>
  <div id="register" :style="{ zoom: `${zoom}%` }">
    <div class="left">
      <div class="bg"></div>
      <div class="desc">
        <div class="en">Welcome !</div>
        <div class="cn">
          {{ $store.state.product_info.name }} - 财务反欺诈平台
        </div>
      </div>
      <img class="img1" src="~@/assets/img/sign/leaf1.png" alt="" srcset="" />
      <img class="img2" src="~@/assets/img/sign/leaf2.png" alt="" srcset="" />
      <img class="img3" src="~@/assets/img/sign/leaf3.png" alt="" srcset="" />
      <img
        class="person"
        src="~@/assets/img/sign/person.png"
        alt=""
        srcset=""
      />
    </div>

    <div class="right">
      <div class="desc">
        <div class="title">欢迎注册</div>
        <div class="go_login">
          已有账号？<span @click="$router.push('/Login')"> 立即登录</span>
        </div>
      </div>
      <div class="form_box">
        <el-form
          autocomplete="off"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="first_name">
            <el-input
              placeholder="姓名（2-20个字符）"
              type="text"
              v-model="ruleForm.first_name"
              autocomplete="off"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item prop="username">
            <el-input
              placeholder="手机号码"
              type="text"
              v-model.number="ruleForm.username"
              autocomplete="on"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item prop="verification_code">
            <div class="input_box">
              <el-input
                placeholder="验证码"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="ruleForm.verification_code"
                maxlength="6"
              ></el-input>
              <div
                class="in_right"
                :class="{ send: showSend }"
                @click="send_code"
              >
                {{ send_msg }}
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              maxlength="50"
              placeholder="常用邮箱"
              v-model="ruleForm.email"
              autocompleted="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <div class="input_box">
              <div
                class="placeholder"
                v-if="!ruleForm.password.length"
                @click="selectInput"
              >
                登录密码（6-20个字符，仅支持数字+字母组合）
              </div>
              <el-input
                ref="password_ele"
                v-model="ruleForm.password"
                maxlength="20"
                :style="check_password ? '' : `font-family: 'password'`"
              ></el-input>
              <div class="in_right" @click="check_password = !check_password">
                <img
                  v-if="!check_password"
                  style="width: 20px"
                  src="~@/assets/img/sign/close_eye.svg"
                  alt=""
                  srcset=""
                />
                <img
                  v-else
                  style="width: 20px"
                  src="~@/assets/img/sign/eye.svg"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="company_name">
            <el-input
              placeholder="就职公司名称"
              maxlength="50"
              v-model="ruleForm.company_name"
            ></el-input>
          </el-form-item>
          <el-form-item prop="position">
            <el-input
              maxlength="50"
              placeholder="职位"
              v-model="ruleForm.position"
            ></el-input>
          </el-form-item>
          <el-form-item prop="attachment">
            <div @click="open_choose_file" class="card_file_box">
              <el-input
                readonly="readonly"
                v-model="ruleForm.attachment"
              ></el-input>
              <div
                style="
                  width: 90%;
                  height: 28px;
                  position: absolute;
                  top: 2px;
                  background: #fff;
                  left: 6px;
                "
              ></div>
              <div class="card_file">
                <div class="text" v-if="!ruleForm.attachment">
                  <span class="span">点击上传</span>
                  <span>证明材料进行身份验证（名片等）</span>
                </div>
                <div class="text" v-else>
                  <span class="span" style="border-bottom: 1px solid #3095fb">{{
                    ruleForm.attachment.name
                  }}</span>
                  <div
                    @click.stop="ruleForm.attachment = ''"
                    class="el-icon-error"
                  ></div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="products">
            <!-- <el-select
              class="select"
              style="width: 100%"
              v-model="ruleForm.products"
              multiple
              placeholder="选择需要开通的系统"
              :popper-append-to-body="false"
            > -->
            <el-select
              class="select"
              style="width: 100%"
              v-model="ruleForm.products"
              placeholder="选择需要开通的系统"
              :popper-append-to-body="false"
            >
              <el-option
                :label="`${$store.state.product_info.register_system_name}`"
                value="1"
              >
              </el-option>

              <!-- <el-option label="数行家·流水尽调" value="2"> </el-option>
              <el-option label="数行家·税务分析" value="3"> </el-option> -->
            </el-select>
          </el-form-item>

          <el-form-item
            style="margin-bottom: 0; margin-top: 40px"
            v-if="$store.state.product_info.show"
          >
            <div>
              <el-checkbox v-model="checked"> </el-checkbox>
              <span style="font-size: 16px; margin-left: 5px; margin-top: 5px"
                >我已阅读并同意

                <span style="color: #3095fb; cursor: pointer" @click="open"
                  >《服务协议》</span
                ></span
              >
            </div>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              style="width: 100%; height: 44px; font-size: 20px"
              @click="submitForm('ruleForm')"
              >立即申请</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <input
      ref="file"
      style="position: fixed; left: -1000px"
      type="file"
      @change="file_change"
    />
  </div>
</template>
<script>
import { apiIter_products, auth_users, sms_register } from "@/api/register";

export default {
  data() {
    const arr = [{ validator: this.toast_fn.bind(this) }];
    return {
      send_msg: "发送验证码",
      check_password: false,
      ruleForm: {
        position: "",
        company_name: "",
        attachment: "",
        password: "",
        verification_code: "",
        email: "",
        username: "",
        first_name: "",
        products: "",
      },
      toast: {},
      checked: false,
      rules: {
        position: arr,
        company_name: arr,
        attachment: arr,
        password: arr,
        verification_code: arr,
        email: arr,
        first_name: arr,
        products: arr,
        username: arr,
      },
      zoom: 0,
      products_list: [],
    };
  },
  computed: {
    showSend() {
      if (this.send_msg == "发送验证码" || this.send_msg == "重新发送") {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.set_zoom();
    // this.apiIter_products();
  },
  mounted() {},
  methods: {
    selectInput() {
      this.$refs.password_ele.$el.querySelector("input").focus();
      // $refs.password_ele.click()
    },
    apiIter_products() {
      apiIter_products().then((res) => {
        // console.log(res);
      });
    },
    set_zoom() {
      var percentage,
        appHeight = 1900; //设置默认屏幕大小
      percentage = (window.innerWidth * 100) / appHeight;
      percentage = Math.floor(percentage);

      this.zoom = percentage;
    },
    open() {
      window.open("/#/agreement");
    },
    toast_fn(rule, value, callback) {
      // if(this.toast[rule])
      if (this.toast[rule.field]) {
        callback(new Error(this.toast[rule.field]));
      } else {
        callback();
      }
      // ;
    },
    file_change(e) {
      const file = e.target.files[0];
      if (
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "image/jpg" ||
        file.type == "image/gif" ||
        file.type == "image/svg" ||
        file.type == "image/bmp"
      ) {
        const imgMax = file.size / 1024 / 1024 < 5;
        if (!imgMax) {
          this.$message.error("上传文件大小不能超过 5MB!");
          return;
        } else {
          this.ruleForm.attachment = file;
        }
      } else {
        this.$message.error("文件格式错误！只能上传图片格式文件。");
        return;
      }
    },
    open_choose_file() {
      this.$refs.file.click();
    },
    count_down(second) {
      if (second == 0) return (this.send_msg = `重新发送`);
      this.send_msg = `重新发送(${second})`;
      setTimeout(this.count_down.bind(this, --second), 1e3);
    },
    send_code() {
      if (this.send_msg == "发送验证码" || this.send_msg == "重新发送") {
        sms_register({ phone_number: this.ruleForm.username })
          .then((res) => {
            this.count_down(60);
          })
          .catch((res) => {
            this.toast = {
              username: res.phone_number,
            };
            this.$refs["ruleForm"].validate((valid) => {});
            setTimeout(() => {
              this.toast = {};
            }, 1e3);
          });
      }
    },
    submitForm(formName) {
      if (this.$store.state.product_info.show && !this.checked)
        return this.$message.error("请先阅读并同意《服务协议》");

      const data = {
        ...this.ruleForm,
        products: this.ruleForm.products.toString(),
      };
      const formData = new FormData();
      if (!data.attachment) {
        delete data.attachment;
      }
      for (const key in data) {
        if (data.hasOwnProperty(key)) formData.append(key, data[key]);
      }
      auth_users(formData)
        .then((res) => {
          if (res.status == 201) {
            this.$alert(res.data.msg, "消息");
            this.$refs[formName].resetFields();
          }
        })
        .catch((res) => {
          this.toast = res;
          this.$refs[formName].validate((valid) => {});
          setTimeout(() => {
            this.toast = {};
          }, 1e3);
        });
    },
  },
  watch: {
    "ruleForm.username": function(now, old) {
      if (now) {
        if (+now == +now) {
        } else {
          this.ruleForm.username = old;
        }
      }
    },
    "ruleForm.verification_code": function(now, old) {
      if (now) {
        if (+now == +now) {
        } else {
          this.ruleForm.verification_code = old;
        }
      }
    },
    "ruleForm.password": function(now, old) {
      if (now) {
        const regExp = /^[a-zA-Z0-9]{1,}$/;
        if (!regExp.test(now)) {
          this.ruleForm.password = old;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/font/index.css";
#register {
  width: 100%;
  height: 100%;
  display: flex;
  > div {
    width: 100%;
    height: 100%;
  }

  .form_box {
    /deep/ .el-select__tags-text {
      font-size: 16px;
    }
    /deep/.el-form-item__content {
      .el-input__inner {
        height: 44px;
        line-height: 44px;
        font-size: 16px;
      }
    }
    /deep/.el-form-item {
      margin-bottom: 20px;
    }
  }
  .left {
    flex: 1;
    position: relative;
    > .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(25, 137, 250, 0.06);
      transform: skewX(-10deg);
      left: -80px;
      top: 0;
    }
    > .desc {
      margin-top: 86px;
      color: #191847;
      font-family: AvenirNext-Bold;
      font-weight: 700;
      letter-spacing: 0;
      text-align: center;

      > .en {
        font-size: 64px;
      }
      > .cn {
        font-size: 36px;
        margin-top: 25px;
      }
    }
    img {
      position: absolute;
    }

    .img1 {
      top: 0;
      left: 0;
    }
    .img2 {
      bottom: 250px;
      left: 0;
    }
    .img3 {
      bottom: 0;
      left: 0;
    }
    .person {
      top: 31.27%;
      right: 15.1%;
      // transform: translateX(-50%);
    }

    // .img_box {
    //   background: red;
    //   > img {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
    // .img_box:nth-child(1) {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 200px;
    //   height: 100%;
    // }
  }

  .right {
    /deep/.el-input__inner {
      &::placeholder {
        font-size: 16px;
      }
    }
    width: 832px;
    padding-left: 93px;
    padding-right: 160px;
    .desc {
      line-height: 1;
      margin-bottom: 40px;
      .title {
        margin-top: 70px;
        font-size: 48px;
        color: #333333;
      }
      .go_login {
        font-size: 16px;
        cursor: pointer;
        margin-top: 10px;
        color: #333333;
        span {
          color: #3095fb;
        }
      }
    }
    .card_file_box {
      position: relative;
      width: 100%;
      .card_file {
        position: absolute;
        width: 100%;
        left: 0;
        height: 44px;
        line-height: 44px;
        top: 0;
        padding-left: 15px;
        .text {
          width: 100%;
          cursor: pointer;
          color: #c0c4cc;
          font-size: 16px;
        }
        .el-icon-error {
          position: absolute;
          right: 15px;
          top: 50%;
          // transform: rotateY(-50%);
          transform: translateY(-50%);
          // top: 0;
        }
        .span {
          color: #3095fb;
        }
      }
    }
    .input_box {
      position: relative;
      .placeholder {
        color: #c0c4cc;
        font-size: 16px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        height: 44px;
        line-height: 44px;
        padding-left: 16px;
      }
      .in_right {
        position: absolute;
        right: 26px;
        font-size: 14px;
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);
        color: #c0c4cc;
      }
      .send {
        color: #1989fa;
      }
    }
  }
}
@media screen and (max-width: 1025px) {
  #register {
    .left {
      display: none;
    }
    .right {
      width: 100%;
    }
  }
}
</style>
